type ThemeVariant = 'qvalon' | 'mdAudit' | 'byDomain';

export default function checkQvalonDomain(theme: ThemeVariant): boolean {
	if (theme === 'qvalon') return true;
	if (theme === 'mdAudit') return false;

	const hostnameSplittedAndReversed = location.hostname.split('.').reverse();
	const secondLevelDomain = hostnameSplittedAndReversed[1] || '';

	return secondLevelDomain.includes('qvalon');
}
